import { createMedia } from '@artsy/fresnel';

import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const AppMedia = createMedia({
  breakpoints: BREAKPOINTS,
});

export const { Media, MediaContextProvider, createMediaStyle } = AppMedia;

export default MediaContextProvider;
